import React from 'react';
import './App.css';

// components
import Menu from './components/Menu/Menu';

export default function App () {
  return (
    <div className="App">
      <Menu />
    </div>
  );
}
