import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserView,
  MobileView,
} from "react-device-detect";

import { makeStyles } from '@material-ui/core/styles';

// Icons
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';

// Menu Elements
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// Structure Elements
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Carousel from 'react-material-ui-carousel'

// Custom Components
import SignUp from './../Account/SignUp';
import SignIn from './../Account/SignIn';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  // App Bar
  appBar: {
    backgroundColor: theme.palette.primary,
    position: 'absolute',
  },
  navTab: {
    fontFamily: 'Sweet Cake Regular',
    fontSize: theme.typography.pxToRem(30),
    borderRadius: 5,
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
      fontSize: theme.typography.pxToRem(25),
      marginLeft: '5%'
    },
  },
  accTab: {
    fontFamily: 'Sweet Cake Regular',
    fontSize: theme.typography.pxToRem(30),
    backgroundColor: 'Transparent',
    borderRadius: 5,
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      lineHeight: 1,
      fontSize: theme.typography.pxToRem(25),
      marginLeft: '5%'
    },
  },
  // HOME PAGE
  panelHome: {
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_home-min.jpg)',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/bg_home_mobile-min.jpg)',
      paddingTop: '10vh'
    },
  },
  logoHome: {
    backgroundImage: 'url(./assets/img/logo_home.png)',
    backgroundColor: 'transparent',
    backgroundSize: '100% 100%',
    boxShadow: 'none',
    position: 'absolute',
    right: '5%',
    width: '50vw',
    height: '90vh',
    [theme.breakpoints.down('sm')]: {
      top: '1%',
      bottom: '0%',
      left: '15%',
      width: '80vw',
      height: '45vh',
    },
  },
  buttonProceed: {
    position: 'absolute',
    bottom: '20%',
    right: '25%',
    padding: theme.spacing(0, 0, 0, 0),
    fontSize: theme.typography.pxToRem(90),
    "&:hover": {
      backgroundColor: 'transparent',
      color: "#F51D58"
    },
    fontFamily: 'yorkwhiteletter',
    lineHeight: 1.5,
    [theme.breakpoints.down('sm')]: {
      right: '25%',
      bottom: '45%',
      fontSize: theme.typography.pxToRem(45),
    },
  },
  // ONLINE PAGE
  panelOnline: {
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_online-min.jpg)',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/bg_online_mobile-min.jpg)',
    },
  },
  // ABROAD PAGE
  panelAbroad: {
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_abroad-min.jpg)',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/bg_abroad_mobile-min.jpg)',
    },
  },
  // JOBS PAGE
  panelJobs: {
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_jobs-min.jpg)',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/bg_jobs_mobile-min.jpg)',
    },
  },
  // ABOUT PAGE
  panelAbout: {
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_about-min.jpg)',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/bg_about_mobile-min.jpg)',
    },
  },
  effectPaintBrushAbout: {
    backgroundImage: 'url(./assets/img/effect_pb_2.png)',
    backgroundSize: '100% 100%',
    height: '95vh',
    width: '100vw',
    margin: theme.spacing(10, 0, 0, 0),
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/effect_pb_2_mobile.png)',
      height: '60vh',
      width: '60vw',
      alignItems: 'center',
      justifyItems: 'center',
      wordWrap: 'break-word',
      margin: theme.spacing(0, 0, 0, 9),
      lineHeight: 1,
    },
  },
  effectHeadingVertical: {
    fontFamily: 'Campus Personal Use',
    fontSize: theme.typography.pxToRem(55),
    color: '#fff685',
    marginTop: theme.spacing(25),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(10),
      fontSize: theme.typography.pxToRem(30),
    },
  },
  effectTextRegularVertical: {
    fontFamily: 'Alata',
    fontSize: theme.typography.pxToRem(22),
    textTransform: 'none',
    color: '#43C4FF',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(1, 3, 0, 3),
      fontSize: theme.typography.pxToRem(10),
    },
  },
  carouselAbout: {
    height: '70vh',
    width: '100vw',
  },
  mobileButtonAbout: {
    backgroundColor: 'transparent',
    "&:hover": {
      backgroundColor: "transparent"
    },
    fontFamily: 'yorkwhiteletter',
    fontSize: theme.typography.pxToRem(90),
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 0, 2),
      fontSize: theme.typography.pxToRem(55),
    },
  },
  // CONTACT PAGE
  panelContact: {
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_contact-min.jpg)',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/bg_contact_mobile-min.jpg)',
    },
  },
  effectHeadingContact: {
    fontFamily: 'Campus Personal Use',
    fontSize: theme.typography.pxToRem(55),
    marginTop: theme.spacing(12),
    color: '#49D8FB',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(28),
    },
  },
  effectTextRegularContact: {
    fontFamily: 'Alata',
    fontSize: theme.typography.pxToRem(25),
    textTransform: 'none',
    color: '#254F82',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  effectTextRegularBoldContact: {
    fontFamily: 'CodeProRegular',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(25),
    textTransform: 'none',
    color: '#1A48A7',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 'bold',
      fontSize: theme.typography.pxToRem(17),
    },
  },
  buttonContact: {
    "&:hover": {
      color: "#49D8FB",
      backgroundColor: 'transparent',
    },
    fontFamily: 'yorkwhiteletter',
    fontSize: theme.typography.pxToRem(90),
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(55),
    },
  },
  effectTearContact: {
    backgroundImage: 'url(./assets/img/effect_tear_1.png)',
    backgroundSize: '100% 100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'absolute',
    right: '0%',
    height: '100vh',
    width: '35vw',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'url(./assets/img/effect_tear_1_mobile.png)',
      top: '20vh',
      width: '100vw',
      height: '80vh',
    },
  },
  // LOGIN PAGE
  panelLogin: {
    padding: theme.spacing(10),
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_login-min.jpg)',
    backgroundSize: '100% 100%',
  },
  panelSignUp: {
    padding: theme.spacing(10),
    height: '100vh',
    backgroundImage: 'url(./assets/img/bg_login-min.jpg)',
    backgroundSize: '100% 100%',
  },
  // SHARED BACKGROUND EFFECTS
  effectPaintBrush: {
    backgroundImage: 'url(./assets/img/effect_pb_0.png)',
    backgroundSize: '100% 100%',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'absolute',
    right: '0%',
    bottom: '0%',
    top: '10%',
    padding: theme.spacing(0, 0, 0, 5),
    width: '45vw',
    height: '90vh',
    [theme.breakpoints.down('sm')]: {
      top: '15vh',
      width: '90vw',
      height: '85vh',
      fontSize: theme.typography.pxToRem(45),
    },
  },
  // SHARED TEXT STYLING
  effectHeading: {
    fontFamily: 'Campus Personal Use',
    fontSize: theme.typography.pxToRem(55),
    marginTop: theme.spacing(12),
    color: '#fff685',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(8),
      fontSize: theme.typography.pxToRem(28),
    },
  },
  effectTextRegular: {
    fontFamily: 'Alata',
    fontSize: theme.typography.pxToRem(25),
    textTransform: 'none',
    color: '#43C4FF',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  effectTextRegularBold: {
    fontFamily: 'Alata',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(25),
    textTransform: 'none',
    color: '#43C4FF',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  effectTextSmall: {
    fontFamily: 'Alata',
    fontSize: theme.typography.pxToRem(15),
    textTransform: 'none',
    color: '#43C4FF',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(10),
    },
  },
  effectTextLarge: {
    fontFamily: 'Alata',
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(45),
    textTransform: 'none',
    color: '#43C4FF',
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(25),
    },
  },
  // SHARED BUTTON STYLING
  button: {
    fontFamily: 'yorkwhiteletter',
    "&:hover": {
      backgroundColor: 'transparent',
      color: "#F51D58"
    },
    color: 'white',
    fontSize: theme.typography.pxToRem(90),
    lineHeight: 1.2,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(55),
    },
  },
  // SHARED ICON STYLING
  icon: {
    fontSize: theme.typography.pxToRem(100),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(30),
    },
  },
}));

function TabPanel (props) {
  const classes = useStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box className={classes.tabPanel} >{children}</Box>
      )}
    </div>
  );
}

function Item (props) {
  const classes = useStyles();
  return (
    <Grid className={classes.effectPaintBrushAbout} item xs={12}>
      <Typography className={classes.effectHeadingVertical} align="center">
        {props.item.heading}
      </Typography>

      <Typography className={classes.effectTextRegularVertical} align="center">
        {props.item.text}
      </Typography>
    </Grid>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps (index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const SimpleTabs = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const items = [
    {
      heading: "OUR AIM",
      text: `Our aim, as SA TEFL Academy, is to make acquiring your TEFL Certificate as
      simple and as enjoyable as possible! As well as, helping find the perfect job for you,
      both online and abroad!`
    },
    {
      heading: "OUR OATH",
      text: `We, as a globally recognized TEFL Institution, are dedicated to helping
    you find your dream job, be it online or abroad!`
    },
    {
      heading: "OUR TEAM",
      text: `Our experienced team is fully qualified with countless hours
    in the teaching field, both online and abroad. We are always
    available to offer support, advice, and inspiration.`
    }
  ]

  return (
    <div className={classes.root}>
      <AppBar
        className={classes.appBar}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons="on"
          aria-label="scrollable auto tabs example"
        >
          <Tab className={classes.navTab} style={{ marginLeft: '10%' }} label="HOME" {...a11yProps(0)} />
          <Tab className={classes.navTab} label="ONLINE" {...a11yProps(1)} />
          <Tab className={classes.navTab} label="ABROAD" {...a11yProps(2)} />
          <Tab className={classes.navTab} label="JOBS" {...a11yProps(3)} />
          <Tab className={classes.navTab} label="ABOUT" {...a11yProps(4)} />
          <Tab className={classes.navTab} label="CONTACT US" {...a11yProps(5)} />
          {/* <Tab className={classes.accTab} style={{ marginLeft: '23%' }} label="LOGIN" {...a11yProps(6)} /> */}
          <Tab className={classes.accTab} disabled style={{ marginLeft: '23%' }} label="SIGN UP" {...a11yProps(7)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <Grid className={classes.panelHome}>
          <Paper className={classes.logoHome}></Paper>
          <Button className={classes.buttonProceed} disableRipple variant="text" onClick={() => { handleChange('buttonClick', 1) }}>
            PROCEED
              </Button>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid className={classes.panelOnline} >
          <Paper className={classes.effectPaintBrush}>
            <Typography className={classes.effectHeading} align="center">
              LOOKING TO GENERATE<br /> A HIGHER INCOME<br />
            </Typography>

            <Typography className={classes.effectTextRegular} align="center">
              Teach online from anywhere in the<br /> world<br /> with our 120-hour TEFL Course.<br />
                  It could not be simpler.<br /><br />
            </Typography>

            <Typography className={classes.effectTextRegularBold} align="center">
              Get your TEFL Certificate and start<br /> teaching online today!<br /><br />
            </Typography>

            <Typography className={classes.effectTextSmall} align="center">
              *120-hour TEFL Course + PDF Certificate
              </Typography>

            <Typography className={classes.effectTextLarge} align="center">
              Price R550
              </Typography>

            <Button className={classes.button} disableRipple variant="text" value={value} onClick={() => { handleChange('buttonClick', 5) }}>
              START
              </Button>
          </Paper>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid className={classes.panelAbroad}>
          <Paper className={classes.effectPaintBrush}>
            <Typography className={classes.effectHeading} align="center">
              LOOKING FOR A NEW<br /> ADVENTURE
              </Typography>

            <Typography className={classes.effectTextRegular} align="center">
              Feeling the drag of everyday life? Why not<br />see where your TEFL can take you?<br /><br />
            </Typography>

            <Typography className={classes.effectTextRegularBold} align="center">
              Join our overseas program and get your<br />chance to see the world!<br /><br />
            </Typography>

            <Typography className={classes.effectTextSmall} align="center">
              *120-hour TEFL Course + Hard Copy Certificate
              </Typography>

            <Typography className={classes.effectTextLarge} align="center">
              Price R750<br />
            </Typography>

            <Button className={classes.button} disableRipple variant="text" value={value} onClick={() => { handleChange('buttonClick', 5) }}>
              START
              </Button>
          </Paper>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid className={classes.panelJobs}>
          <Paper className={classes.effectPaintBrush}>
            <Typography className={classes.effectHeading} align="center">
              LOOKING FOR A<br />JOB
              </Typography>

            <Typography className={classes.effectTextRegular} align="center">
              SA TEFL Academy<br />offers full and<br />part-time jobs through our<br />online school,
              </Typography>

            <Typography className={classes.effectTextRegularBold} align="center">
              SA TEFL Talk.<br /><br />
            </Typography>

            <Typography className={classes.effectTextLarge} align="center">
              Join our Online<br />Teaching Team today!
              </Typography>

            <Button className={classes.button} disableRipple variant="text" href={`mailto:info.sateflacademy@gmail.com?subject=Job Enquiry&body=Hello,\nI’d like to find out more about teaching online.`} value={value}>
              APPLY
              </Button>
          </Paper>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Grid className={classes.panelAbout}>
          <MobileView>
            <Carousel className={classes.carouselAbout} autoPlay={false} indicators={false} navButtonsAlwaysVisible>
              {items.map((item, i) => <Item key={i} item={item} />)}
            </Carousel>
            <Button className={classes.mobileButtonAbout} disableRipple variant="text" value={value} onClick={() => { handleChange('buttonClick', 5) }}>
              JOIN
            </Button>
          </MobileView>

          <BrowserView>
            <Grid container>
              <Grid className={classes.effectPaintBrushAbout} item xs={4}>
                <Typography className={classes.effectHeadingVertical} align="center">
                  Our Aim
                  </Typography>

                <Typography className={classes.effectTextRegularVertical} align="center">
                  Our aim,<br />as SA TEFL Academy,<br />is to make acquiring<br />your TEFL Certificate as<br />
                    simple and as enjoyable<br />as possible!<br />As well as, helping find<br />the perfect job for you,<br />
                    both online<br />and abroad!
                  </Typography>
              </Grid>
              <Grid className={classes.effectPaintBrushAbout} item xs={4}>
                <Typography className={classes.effectHeadingVertical} align="center">
                  Our Oath
                  </Typography>

                <Typography className={classes.effectTextRegularVertical} align="center">
                  We, as a globally<br />recognized TEFL<br />Institution, are<br />dedicated to helping<br />
                    you find your dream<br />job, be it online or<br />abroad!
                  </Typography>

                <Button className={classes.button} disableRipple variant="text" value={value} onClick={() => { handleChange('buttonClick', 5) }}>
                  JOIN
                  </Button>
              </Grid>
              <Grid className={classes.effectPaintBrushAbout} item xs={4}>
                <Typography className={classes.effectHeadingVertical} align="center">
                  Our Team
                  </Typography>

                <Typography className={classes.effectTextRegularVertical} align="center">
                  Our experienced team<br />is fully qualified<br />with countless hours<br />
                    in the teaching field,<br />both online<br />and abroad.<br />We are always<br />
                    available to offer<br />support, advice, and<br />inspiration.
                  </Typography>
              </Grid>
            </Grid>
          </BrowserView>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Grid className={classes.panelContact}>
          <Paper className={classes.effectTearContact}>
            <Typography className={classes.effectHeadingContact} align="center">
              Get in touch<br />
            </Typography>
            <br />
            <Typography className={classes.effectTextRegularBoldContact} align="center">
              Feel free to contact us via email.<br /> We would love to hear from you!<br /><br />
            </Typography>

            <Typography className={classes.effectTextRegularContact} align="center">
              Our Team will be happy to<br />
                answer any questions about our<br />
                services or for more details<br />
                about the company.<br />
            </Typography>

            <Button className={classes.buttonContact} disableRipple variant="text" href={`mailto:sateflacademy@gmail.com?subject=Enquiry&body=Hi there,\nI’d like to find out more about your 120–hour TEFL Course.`}>
              EMAIL US
              </Button>
            <br />
            <Button disableRipple variant="text" href={`https://www.facebook.com/SATeflAcademy/`} target="_blank"><FacebookIcon className={classes.icon} /></Button>
            <Button disableRipple variant="text" href={`https://twitter.com/sa_tefl_academy?ref_src=twsrc%5Etfw`} target="_blank"><TwitterIcon className={classes.icon} /></Button>
            <Button disableRipple variant="text" href={`https://www.instagram.com/sateflacademy/`} target="_blank"><InstagramIcon className={classes.icon} /></Button>
          </Paper>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Grid className={classes.panelLogin}>
          <SignIn></SignIn>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Grid className={classes.panelSignUp}>
          <SignUp></SignUp>
        </Grid>
      </TabPanel>
    </div>
  );
};

export default SimpleTabs;
